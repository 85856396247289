const localeData: LocaleData = {
  defaultLocale: 'de',
  locales: ['en', 'de', 'fr'],
};

export const environment = {
  production: false,
  hmr: false,
  localeData,
  authApiUrl: 'https://dev-auth-service.apps.exoscale-ch-gva-2-0.appuio.cloud',
  encryptionKey: '4eDxl3GkkOLSMFSt6QUjjceXb033YPDYsY7QqWCx',
  baseTimeout: 1800,
  kendoxActive: false,
  baseUrl: 'localhost:3000',
  name: 'dev',
  client: 'blpk',
  countdownTimeInSeconds: 900,
  idleTimeInSeconds: 2,
  automaticLogOutActivated: true,
  config: undefined,
  bannerText: 'Development Environment',
};

import { LocaleData } from '../app/core/interfaces/locale';
